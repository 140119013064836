import React from "react"
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionUpdate from "../../components/sections/VideosSectionUpdate";

const CylindricalLocksVideosPage = () => (
  <MainContainer>
    <HeadDataNew 
      title='Cylindrical Locks Videos Page' 
      description='A list of Command Access Cylindrical Locks videos.' 
    />

    <VideosSectionUpdate 
      title="Cylindrical Locks"
      category="Cylindrical Locks"
      noVideos
    />
  </MainContainer>
)

export default CylindricalLocksVideosPage